<template>
  <div
    class="input-block"
    :class="{
      'input-block--required': required,
      'input-block--disabled': disabled,
      'input-block--readonly': readonly,
      'input-block--error':
        (errorsMessages && errorsMessages.length > 0) || errorMessage,
      'input-block--inline': inline,
    }"
  >
    <div class="label" v-if="label !== undefined && label !== ''">
      {{ label }}
    </div>
    <div class="input" :class="`input--${inputStyle}`">
      <template v-if="modelValue === undefined">
        <div class="checkbox" v-for="(item, index) in items" :key="item.label">
          <input
            ref="Checkbox"
            type="checkbox"
            :id="`${id}-${index}`"
            :disabled="disabled"
            :readonly="readonly"
            :name="name ? name : id"
            :value="item.value"
          />
          <label
            @click="changesHandlersFunc(index, item.value)"
            :for="`${id}-${index}`"
            ><span>{{ item.label }}</span></label
          >
        </div>
      </template>
      <template v-else>
        <div class="checkbox" v-for="(item, index) in items" :key="item.label">
          <input
            ref="Checkbox"
            type="checkbox"
            @change="updateInput"
            :id="`${id}-${index}`"
            :disabled="disabled"
            :readonly="readonly"
            :name="name ? name : id"
            :value="item.value"
            v-model="multipleCheckboxValues"
          />
          <label :for="`${id}-${index}`"
            ><span>{{ item.label }}</span></label
          >
        </div>
      </template>
    </div>
    <div
      class="input-text input-text--info text-align-left"
      v-if="textInfo && !errorsMessages && !errorMessage"
    >
      {{ textInfo }}
    </div>
    <div
      class="input-text input-text--error text-align-left"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate'

export default {
  name: 'Checkbox',

  props: {
    modelValue: {
      type: [Array, Boolean],
      default: undefined,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    textInfo: {
      type: String,
    },
    textError: {
      type: String,
    },
    name: {
      type: String,
    },
    inputStyle: {
      type: String, // 'block' or 'inline'
      default: 'block',
    },
  },

  data(props) {
    if (props.modelValue === undefined) {
      const checkedValues = []
      const changesHandlers = []
      const errorsMessages = []

      props.items.forEach((item) => {
        const { checked, handleChange, errorMessage } = useField(
          props.id,
          undefined,
          {
            type: 'checkbox',
            checkedValue: item.value,
          },
        )

        checkedValues.push(checked)
        changesHandlers.push(handleChange)
        if (errorMessage?.value !== undefined) {
          errorsMessages.push(errorMessage.value)
        }
      })

      return {
        checkedValues,
        changesHandlers,
        errorsMessages,
        errorMessage: null,
      }
    }
    return {
      errorsMessages: null,
      errorMessage: props.textError,
      multipleCheckboxValues: props.modelValue,
    }
  },

  emits: [
    // List of Component emitted events
    'update:modelValue',
  ],

  watch: {
    modelValue() {
      if (this.modelValue !== undefined) {
        this.multipleCheckboxValues = this.modelValue
      }
    },
  },

  mounted() {
    if (this.modelValue !== undefined) {
      this.multipleCheckboxValues = this.modelValue
    }
    this.emitter.on('toggle-check', this.toggleCheck)
  },

  methods: {
    updateInput() {
      this.$emit('update:modelValue', this.multipleCheckboxValues)
    },
    changesHandlersFunc(index, value) {
      if (typeof this.changesHandlers[index] !== 'undefined') {
        const Handler = this.changesHandlers[index]
        Handler(value)
      }
    },
    // Method called in Lists
    toggleCheck(e) {
      console.log('e', e)
      // If Select All checkbox changes
      if (e.checkboxes === 'ALL') {
        this.multipleCheckboxValues = this.items
          .map((item) => {
            let { checked } = item
            // Toggle check of the checkbox
            checked = !checked
            return !checked ? undefined : item.value
          })
          .filter((item) => item !== undefined)
      } else {
        // If an other Checkbox changes
        // Check or Uncheck the Checkbox
        this.multipleCheckboxValues = this.multipleCheckboxValues.filter(
          (item) => e.checkboxes.includes(item),
        )
      }
    },
  },
}
</script>
